import Head from 'next/head';
import { Login } from 'modules/Auth/components/Login/Login';

function LoginPage(): JSX.Element | null {
  return (
    <>
      <Head>
        <link rel="canonical" href="/login" key="canonical" />
      </Head>
      <Login />
    </>
  );
}

export default LoginPage;
